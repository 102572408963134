<template>
  <div :class="isMobile ? 'm-talents':'talents'">
    <div :class="isMobile ? 'm-banner':'banner'">
      <img src="../../assets/image/talents/banner.png" alt="">
      <div class="white" :class="isMobile ? 'm-talents-text':'talents-text'">
        <div :class="isMobile ? 'm-box-all':'box-all'">
          <div :class="isMobile ? 'm-box-foot':'box-foot'"></div>
        </div>
        <div :class="isMobile ? 'm-title':'title'">雏鹰人才</div>
        <div :class="isMobile ? 'm-description':'description'">汇聚人才所在 · 挖掘时代精英</div>
      </div>
    </div>
    <div :class="isMobile ? 'm-service':'service'">
      <div :class="isMobile ? 'm-service-title':'service-title'">雏鹰人才综合服务</div>
      <div :class="isMobile ? 'm-service-img':'service-img'">
        <img src="../../assets/image/talents/talents.png" alt="" />
      </div>
    </div>
    <div :class="isMobile ? 'm-contact':'contact'">
      <div :class="isMobile ? 'm-contact-title':'contact-title'">我们可以这么合作</div>
      <div v-if="!isMobile" class="flow">
        <ul>
          <li><img class="icon_c" :src="company"/>用人单位<img class="vector" :src="vector" alt=""></li>
          <li>提出人才需求<img class="vector" :src="vector" alt=""></li>
          <li>进入系统并分析<img class="vector" :src="vector" alt=""></li>
          <li>人才匹配列表<img class="vector" :src="vector" alt=""></li>
          <li>预选候选人<img class="vector" :src="vector" alt=""></li>
          <li><img class="icon_c" :src="job"/>学校就业<img class="vector" :src="vector" alt=""></li>
          <li>提供相关资料<img class="vector" :src="vector" alt=""></li>
          <li>发布人才情况<img class="vector" :src="vector" alt=""></li>
          <li>形成能力标签<img class="vector" :src="vector" alt=""></li>
          <li>匹配企业需求<img class="vector" :src="vector" alt=""></li>
          <li><img class="icon_c" :src="jobWanted"/>人才求职<img class="vector" :src="vector" alt=""></li>
          <li>填写技能及诉求<img class="vector" :src="vector" alt=""></li>
          <li>采集入库<img class="vector" :src="vector" alt=""></li>
          <li>匹配工作机会<img class="vector" :src="vector" alt=""></li>
          <li>双选机制<img class="vector" :src="vector" alt=""></li>
          <li><div class="serve">进入服务</div></li>
        </ul>
        <div class="btn" @click="goto"><el-button>进入系统<i class="el-icon-right margin-l-10"></i></el-button></div>
      </div>
      <div v-else class="m-flow">
        <div class="m-flow-content">
          <ul>
            <li><div class="icon_c"><img :src="company"/></div><p>用人单位</p><div class="vector"><img :src="vector" alt="" /></div></li>
            <li>提出人才需求<div class="vector"><img :src="vector" alt="" /></div></li>
            <li>进入系统并分析<div class="vector"><img :src="vector" alt="" /></div></li>
            <li>人才匹配列表<div class="vector"><img :src="vector" alt="" /></div></li>
            <li>预选候选人<div class="vector"><img :src="vector" alt="" /></div></li>
          </ul>
          <ul>
            <li><div class="icon_c"><img :src="job"/></div><p>学校就业</p><div class="vector"><img :src="vector" alt="" /></div></li>
            <li>提供相关资料<div class="vector"><img :src="vector" alt="" /></div></li>
            <li>发布人才情况<div class="vector"><img :src="vector" alt="" /></div></li>
            <li>形成能力标签<div class="vector"><img :src="vector" alt="" /></div></li>
            <li>匹配企业需求<div class="vector"><img :src="vector" alt="" /></div></li>
          </ul>
          <ul>
            <li><div class="icon_c"><img :src="jobWanted"/></div><p>人才求职</p><div class="vector"><img :src="vector" alt="" /></div></li>
            <li>填写技能及诉求<div class="vector"><img :src="vector" alt="" /></div></li>
            <li>采集入库<div class="vector"><img :src="vector" alt="" /></div></li>
            <li>匹配工作机会<div class="vector"><img :src="vector" alt="" /></div></li>
            <li>双选机制<div class="vector"><img :src="vector" alt="" /></div></li>
          </ul>
        </div>
        <div class="enter">进入系统</div>
        <div class="m-btn-serve" @click="$router.push('/coming-soon')"><el-button>进入服务<i class="el-icon-right margin-l-10"></i></el-button></div>
      </div>
    </div>
    <div :class="isMobile ? 'm-case':'case'">
      <div :class="isMobile ? 'm-case-title':'case-title'">合作案例展示</div>
      <div v-if="!isMobile" class="case-content">
        <div class="left-btn" @click="handleLeft"><img src="../../assets/image/talents/left.png" alt=""></div>
        <div class="right-btn" @click="handleRight"><img src="../../assets/image/talents/right.png" alt=""></div>
        <el-tabs v-model="activeTab" type="card" @tab-click="handleTabClick">
          <el-tab-pane v-for="(item, index) in caseList" :label="item.label" :name="item.type.toString()" :key="index">
          </el-tab-pane>
        </el-tabs>
        <ul class="box">
            <li class="case-text" v-for="(item, index) in caseList" :key="index">
              <template v-if="item.type == activeTab">
                <div class="picture"><img :src="item.picture" alt=""></div>
                <div class="text">
                  <p class="title">{{ item.title }}</p>
                  <div class="left-i"><img src="../../assets/image/talents/left-i.png" alt=""></div>
                  <p class="desc">{{ item.text }}</p>
                  <div class="right-i"><img src="../../assets/image/talents/right-i.png" alt=""></div>
<!--                  <div class="more">-->
<!--                    <el-button>了解更多<i class="el-icon-right margin-l-10"></i></el-button>-->
<!--                  </div>-->
                </div>
              </template>
            </li>
        </ul>
      </div>
      <div v-else class="m-case-content">
        <el-tabs v-model="activeTab" tab-position="left" style="height: 8rem;">
          <el-tab-pane v-for="(item, index) in caseList" :label="item.label" :name="item.type.toString()" :key="index">
            <div class="">
              <div class="m-box" v-for="(item, index) in caseList" :key="index">
                <div class="m-case-text" v-if="item.type == activeTab">
                  <div class="picture"><img :src="item.picture" alt=""></div>
                  <div class="m-text">
                    <p class="m-title">{{ item.title }}</p>
                    <div class="m-left-i"><img src="../../assets/image/talents/left-i.png" alt=""></div>
                    <p class="m-desc">{{ item.text }}</p>
                    <div class="m-right-i"><img src="../../assets/image/talents/right-i.png" alt=""></div>
                    <!--                  <div class="more">-->
                    <!--                    <el-button>了解更多<i class="el-icon-right margin-l-10"></i></el-button>-->
                    <!--                  </div>-->
                  </div>
                </div>
              </div>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
  </div>
</template>

<script>
import { isMobileDevice } from "../../utils/detectDevice";
import company from '../../assets/image/talents/company.png'
import job from '../../assets/image/talents/job.png'
import jobWanted from '../../assets/image/talents/job-wanted.png'
import vector from '../../assets/image/talents/vector.png'
import axios from "axios";
import { baseUrl } from "../../utils/settings";
export default {
  name: "talents",
  data() {
    return {
      isMobile: isMobileDevice(),
      company,
      vector,
      jobWanted,
      job,
      activeTab: '1',
      caseList: []
    }
  },
  mounted() {
    this.initCase()
  },
  methods: {
    handleTabClick() {
      console.log(this.activeTab)
    },
    handleLeft() {
      const num = Number(this.activeTab) - 1
      if( num<= 0 ) {
        this.activeTab = '6'
      }else {
        this.activeTab = num.toString()
      }
    },
    handleRight() {
      const num = Number(this.activeTab) + 1
      if( num >= 7 ){
        this.activeTab = '1'
      }else {
        this.activeTab = num.toString()
      }
    },
    goto() {
      let routeUrl =  this.$router.resolve({ path: './coming-soon'})
      window.open(routeUrl.href, '_blank');
    },
    async initCase() {
      const response = await axios.get(`${baseUrl}/system/hzal/webList`)
      this.caseList = response.data.data
        this.caseList = this.caseList.map(item => {
          return {
            ...item,
            label: item.type == 1 ? '金融行业':item.type == 2? '日化行业':item.type == 3? '母婴行业':item.type == 4? '美妆行业':item.type == 5? '教育行业':item.type == 6? '零售行业': null
          }
        })
    }
  }
}
</script>

<style scoped lang="less">
.talents {
  width: 100%;
  height: 100%;
  color: #11142C;
  .banner {
    width: 100%;
    height: 960px;
    position: relative;
    img {
      width: 100%;
      height: 100%;
    }
    .talents-text {
      position: absolute;
      top: 35%;
      left: 0;
      right: 0;
      box-sizing: border-box;
      text-align: center;
      width: 1280px;
      margin: 0 auto;
      color: white;
      .title {
        font: 80px AlimamaShuHeiTi;
        font-weight: 700;
      }
      .description {
        width: 960px;
        font-size: 30px;
        font-weight: 400;
        margin: 60px auto 0;
      }
      .box-all{
        width: 180px;
        height: 160px;
        border-top: 8px solid #ffffff;
        border-bottom: 8px solid #ffffff;
        z-index: 20;
        position: absolute;
        top: -15%;
        left: 50%;
        transform: translateX(-50%);
      }
      .box-all:before, .box-all:after{
        position:absolute;
        width: 0px;
        height: 15px;
        content: "";
        top: 0;
      }
      .box-all:before, .box-foot:before{
        border-left: 8px solid #ffffff;
        left: 0px;
      }
      .box-all:after,.box-foot:after{
        border-right: 8px solid #ffffff;
        right: 0;
      }
      .box-foot{
        position:absolute;
        bottom: 0;
        width: 100%;
        left: 0;
      }
      .box-foot:before, .box-foot:after{
        position:absolute;
        width: 0px;
        height: 15px;
        content: "";
        bottom: 0;
      }
    }
  }
  .service {
    width: 100%;
    height: 960px;
    overflow: hidden;
    .service-title {
      width: 1280px;
      font: 60px AlimamaShuHeiTi;
      color: #121212;
      text-align: center;
      margin: 100px auto 80px;
    }
    .service-img {
      width: 810px;
      height: 700px;
      margin: 0 auto;
      img {
        width: 90%;
        height: auto;
      }
    }
  }
  .contact {
    width: 100%;
    height: 960px;
    background: url("../../assets/image/talents/collaborate.png") no-repeat;
    background-size: 100% 100%;
    overflow: hidden;
    .contact-title {
      width: 1280px;
      color: white;
      font: 60px AlimamaShuHeiTi;
      font-weight: 700;
      margin: 100px auto;
      text-align: center;
    }
    .flow {
      width: 1280px;
      height: 380px;
      margin: 0 auto;
      color: white;
      ul {
        width: 100%;
        height: 380px;
        position: relative;
        .vector {
          display: inline-block;
          width: 22px;
          height: 20px;
          position: absolute;
          right: -35px;
          bottom: 50%;
          transform: translateY(50%);
        }
        li {
          position: relative;
          float: left;
          width: 190px;
          height: 60px;
          line-height: 60px;
          background-color: rgba(255, 255, 255, .3);
          margin-right: 45px;
          margin-bottom: 100px;
          border-radius: 4px;
          font-size: 20px;
          text-align: center;
          .icon_c {
            display: inline-block;
            width: 60px;
            height: 60px;
            vertical-align: bottom;
          }
        }
        li:nth-child(11),li:nth-child(12),li:nth-child(13),li:nth-child(14),li:nth-child(15),li:nth-child(16){
          margin-bottom: 0;
        }
        li:first-child, li:nth-child(6), li:nth-child(11) {
          background-color: #102867;
        }
        li:last-child {
          position: absolute;
          right: 0;
          border: 0;
          width: 100px;
          height: 380px;
          margin-right: 0;
          font-size: 40px;
          border: 1px dashed #ffffff;
          .serve {
            width: 40px;
            height: 380px;
            margin: 0 auto;
            display: flex;
            align-items: center;
          }
        }
      }
      .btn {
        width: 168px;
        height: 48px;
        margin: 100px auto 0;
      }
      .btn .el-button--small {
        padding: 13px 28px;
        color: #102867;
        font-size: 20px;
      }
      .btn .el-button:focus, .el-button:hover {
        border-color: white;
        background-color: white;
      }
    }
  }
  .case {
    width: 100%;
    height: 960px;
    overflow: hidden;
    .case-title {
      width: 1280px;
      font: 60px AlimamaShuHeiTi;
      color: #121212;
      text-align: center;
      margin: 100px auto;
    }
    .case-content {
      width: 1280px;
      height: 686px;
      margin: 0 auto;
      position: relative;
      .left-btn, .right-btn {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        cursor: pointer;
        width: 60px;
        height: 60px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .left-btn {
        left: 0;
      }
      .right-btn {
        right: 0;
      }
      .box {
        width: 1120px;
        height: 540px;
        background-color: white;
        box-shadow: 0px 0px 20px 0px rgba(0,0,0,.1);
        margin: 20px auto;
        position: relative;
        .case-text {
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          margin: 0 40px;
          position: absolute;
          left: 0;
          top: 0;
          .picture {
            width: 640px;
            height: 460px;
            margin-right: 40px;
            img {
              width: 100%;
              height: 100%;
            }
          }
          .text {
            width: 360px;
            color: #11142C;
            .title {
              font-size: 20px;
              font-weight: bold;
              line-height: 32px;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              overflow: hidden;
              -webkit-line-clamp: 2; /* 控制显示的行数 */
              text-overflow: ellipsis;
              margin-bottom: 20px;
            }
            .left-i, .right-i {
              width: 42px;
              height: 36px;
              img {
                width: 100%;
                height: 100%;
              }
            }
            .right-i {
              float: right;
            }
            .desc {
              font-size: 18px;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              overflow: hidden;
              -webkit-line-clamp: 6; /* 控制显示的行数 */
              text-overflow: ellipsis;
            }
            .more {
              margin-top: 80px;
              .el-button {
                border: none;
                font-size: 18px;
                color: #102867;
              }
              .el-button:focus, .el-button:hover {
                background-color: white;
              }
            }
          }
        }

      }
      ::v-deep .el-tabs--card>.el-tabs__header {
        border-bottom: none;
      }
      ::v-deep .el-tabs--card>.el-tabs__header .el-tabs__nav {
        border: none;
        background-color: #f3f4f7;
      }
      ::v-deep .el-tabs__nav {
        left: 50%;
        top: 0;
        transform: translateX(-50%)!important;
        border-radius: 60px;
      }
      ::v-deep .el-tabs--card>.el-tabs__header .el-tabs__item.is-active {
        border: none;
        background-color: #102867;
        color: white;
        border-radius: 60px;
      }
      ::v-deep .el-tabs__item {
        border: none;
        height: 60px;
        line-height: 60px;
        padding: 0 40px;
        font-size: 20px;
        color: #102867;
      }
    }
  }
}
.m-talents {
  .m-banner {
    width: 100%;
    height: 5rem;
    position: relative;
    img {
      width: 100%;
      height: 100%;
    }
    .m-talents-text {
      position: absolute;
      top: 35%;
      left: 0;
      right: 0;
      box-sizing: border-box;
      text-align: center;
      margin: 0 .8rem;
      .m-title {
        font-size: .5rem;
        font-family: AlimamaShuHeiTi;
      }
      .m-description {
        font-size: .24rem;
        margin: .5rem auto 0;
        width: 90%;
      }
      .m-box-all{
        width: 1.25rem;
        height: 1rem;
        border-top: .05rem solid #ffffff;
        border-bottom: .05rem solid #ffffff;
        z-index: 20;
        position: absolute;
        top: -7.5%;
        left: 50%;
        transform: translateX(-50%);
      }
      .m-box-all:before, .m-box-all:after{
        position:absolute;
        width: 0px;
        height: .12rem;
        content: "";
        top: 0;
      }
      .m-box-all:before, .m-box-foot:before{
        border-left: .05rem solid #ffffff;
        left: 0px;
      }
      .m-box-all:after,.m-box-foot:after{
        border-right: .05rem solid #ffffff;
        right: 0;
      }
      .m-box-foot{
        position:absolute;
        bottom: 0;
        width: 100%;
        left: 0;
      }
      .m-box-foot:before, .m-box-foot:after{
        position:absolute;
        width: 0px;
        height: .12rem;
        content: "";
        bottom: 0;
      }
    }
  }
  .m-service {
    width: 100vw;
    margin: .5rem auto;
    .m-service-title {
      font: .55rem AlimamaShuHeiTi;
      color: #121212;
      text-align: center;
      margin: 0px auto .5rem;
    }
    .m-service-img {
      width: 80%;
      height: 7rem;
      margin: 0 auto;
      img {
        width: 100%;
        height: auto;
      }
    }
  }
  .m-contact {
    width: 100%;
    height: 12.8rem;
    background: url("../../assets/image/talents/collaborate.png") no-repeat center;
    background-size: cover;
    overflow: hidden;
    .m-contact-title {
      font: .55rem AlimamaShuHeiTi;
      color: white;
      text-align: center;
      margin: .5rem auto;
    }
    .m-flow {
      .m-flow-content {
        display: flex;
        width: 90%;
        margin: 0 auto;
        >ul:nth-child(2) {
          margin: 0 .35rem;
        }
        >ul {
          flex: 1;
          height: 8.1rem;
          font-size: .32rem;
          li {
            width: 100%;
            height: 1rem;
            background-color: rgba(255,255,255,.3);
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            position: relative;
            margin: .6rem 0;
            .vector {
              position: absolute;
              top: 120%;
              left: 50%;
              transform: translateX(-50%);
              width: .32rem;
              height: .3rem;
              img {
                transform: rotate(90deg);
                width: 100%;
                height: 100%;
              }
            }
            .icon_c {
              width: .8rem;
              height: .8rem;
              img {
                width: 100%;
                height: auto;
              }
            }
            p {
              margin-right: .1rem;
            }
          }
          li:first-child {
            background-color: #102867;
          }
          li:first-child, li:last-child{
            margin: 0;
          }
        }
      }
      .enter {
        width: 90%;
        height: 1rem;
        line-height: 1rem;
        background-color: rgba(255,255,255,.3);
        margin: 0 auto;
        text-align: center;
        font-size: .45rem;
        letter-spacing: .1rem;
        border: 1px dashed #ffffff;
      }
      .m-btn-serve .el-button--small {
        margin: .5rem auto 0;
        color: #102867;
        background-color: #ffffff;
        display: block;
        padding: .2rem 0.33rem .19rem .33rem;
        font-size: 0.28rem;
      }
    }
  }
  .m-case {
    width: 100%;
    height: 100%;
    margin: .5rem 0;
    .m-case-title {
      font: .55rem AlimamaShuHeiTi;
      color: #121212;
      text-align: center;
      margin-bottom: .5rem;
    }
    .m-case-content {
      width: 100vw;
      height: 100%;
      color: #11142C;
      font-size: .32rem;
      ::v-deep .el-tabs--left .el-tabs__nav-wrap.is-left::after, .el-tabs--left .el-tabs__nav-wrap.is-right::after, .el-tabs--right .el-tabs__nav-wrap.is-left::after, .el-tabs--right .el-tabs__nav-wrap.is-right::after{
        width: .08rem;
      }
      ::v-deep .el-tabs--left .el-tabs__header.is-left {
        margin-right: .2rem;
      }
      ::v-deep .el-tabs__item {
        color: #666;
        height: 1rem;
        line-height: 1rem;
        font-size: .35rem;
        padding: 0 .5rem;
      }
      ::v-deep .el-tabs__item.is-active {
        color: #102867;
      }
      ::v-deep .el-tabs__active-bar {
        background-color: #102867;
        width: .08rem;
      }
      .m-box {
        margin: 0 .4rem 0 .15rem;
        li {
          width: 100%;
          height: 100%;
        }
        .picture {
          width: 100%;
          height: 3.5rem;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .m-text {
          .m-title {
            margin: .2rem .3rem .1rem .3rem;
            font-size: .35rem;
            font-weight: bold;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            overflow: hidden;
            -webkit-line-clamp: 1; /* 控制显示的行数 */
            text-overflow: ellipsis;
          }
          .m-left-i, .m-right-i {
            width: .6rem;
            height: .5rem;
            img {
              width: 100%;
              height: auto;
            }
          }
          .m-right-i {
            float: right;
          }
          .m-desc {
            margin: .2rem .4rem;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            overflow: hidden;
            -webkit-line-clamp: 4; /* 控制显示的行数 */
            text-overflow: ellipsis;
          }
        }
      }
    }
  }
}
</style>
